import { CartPaymentMethodEnum } from '../../../../core/enums/cart-payment-method.enum';

export interface ICheckoutPaymentBoxData {

  name: string;
  type: CartPaymentMethodEnum;
  description: string;
  loader: boolean;

}

export const PAYMENTS: Array<ICheckoutPaymentBoxData> = [
  {
    name: 'Carta di credito',
    type: CartPaymentMethodEnum.Stripe,
    description: 'Confermando con il pagamento mediante carta di credito verrai indirizzato al sito del circuito della tua carta per confermare l’operazione.',
    loader: false,
  },
  {
    name: 'Bonifico',
    type: CartPaymentMethodEnum.Bank,
    description: 'Utilizzando questa formula la validazione e la ricevuta dell’operazione saranno disponibili solo dopo la conferma di ricezione del bonifico da parte della segreteria.',
    loader: false,
  }
];

export const SPLITTED_PAYMENTS: Array<ICheckoutPaymentBoxData> = [
  {
    name: 'Carta di credito',
    type: CartPaymentMethodEnum.StripeSplitted2,
    description: 'Confermando con il pagamento mediante carta di credito verrai indirizzato al sito del circuito della tua carta per confermare l’operazione.',
    loader: false,
  },
  {
    name: 'Bonifico',
    type: CartPaymentMethodEnum.BankTransferSplitted2,
    description: 'Utilizzando questa formula la validazione e la ricevuta dell’operazione saranno disponibili solo dopo la conferma di ricezione del bonifico da parte della segreteria.',
    loader: false,
  },
];
