import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { environment } from '../../../../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CartPaymentMethodEnum } from '../../../../core/enums/cart-payment-method.enum';
import { CheckOutPaymentData } from '../check-out-payment-data';
import { ProductCategoryMacroTypeEnum } from '../../../../core/enums/product-category-macro-type.enum';

@Component({
  selector: 'app-checkout-confirm',
  templateUrl: './checkout-confirm.component.html',
  styleUrls: ['./checkout-confirm.component.scss'],
})
export class CheckoutConfirmComponent implements OnInit {

  payment = '';
  cartPaymentMethod = CartPaymentMethodEnum;

  productCategoryMacroType = ProductCategoryMacroTypeEnum;

  IBAN = environment.payments.IBAN;

  constructor(
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {
      method: CartPaymentMethodEnum,
      amount: number,
      checkOutPaymentData?: CheckOutPaymentData
    }) {
  }

  ngOnInit(): void {

    this.payment = this.getPaymentType();

  }

  getPaymentType(): string {

    switch (this.data.method) {

      case CartPaymentMethodEnum.Bank:
        return 'bonifico';

      case CartPaymentMethodEnum.Stripe:
        return 'carta di credito';

      case CartPaymentMethodEnum.StripeSplitted2:
        return 'pagamento a rate con carta di credito';

      case CartPaymentMethodEnum.BankTransferSplitted2:
        return 'pagamento a rate con bonifico';

    }

    return '';

  }

  copy(): void {

    this.snackbar.open('IBAN copiato correttamente!', 'X', {
      duration: 3000,
      panelClass: 'general-snackbar-panel',
    });

  }

}
