import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GeneralService } from '../../core/lib/general.service';
import { ProfileController } from '../../core/controllers/profile.controller';
import { CheckOutPaymentData } from './checkout-payment/check-out-payment-data';
import { CartPaymentMethodEnum } from '../../core/enums/cart-payment-method.enum';

@Component({
  selector: 'app-checkout',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit {

  indirectSelectedPaymentType?: CartPaymentMethodEnum; // callback value setted on bank/finance payment
  couponAvailableAmount = 0;

  constructor(
    private dialogRef: MatDialogRef<CheckOutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CheckOutPaymentData,
    private general: GeneralService,
    private profileController: ProfileController,
  ) {

  }

  ngOnInit(): void {

    this.getCouponAmount();

  }

  getCouponAmount() {

    this.profileController
      .getCouponAmount()
      .then((amount: number) => this.couponAvailableAmount = amount);

  }

  setPayment(payment: CartPaymentMethodEnum | null) {

    if (!payment) {

      this.close();

    } else {

      this.indirectSelectedPaymentType = payment;

    }

  }

  close(): void {

    if (this.indirectSelectedPaymentType) {

      this.general.navigateTo(['/']);

    }

    this.dialogRef.close();

  }

}
