import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import {
  NotAvailableInstallmentDialogComponent,
} from './not-available-installment-dialog/not-available-installment-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NotificationsWidgetModule } from '../../_components/notifications-widget/notifications-widget.module';

@NgModule({
  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NotificationsWidgetModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: HomeComponent,
            data: {
              title: '',
              urls: [{ title: 'Home', url: '/home' }, { title: 'Home' }],
            },
          }],
      },
    ]),
  ],
  declarations: [
    HomeComponent,
    NotAvailableInstallmentDialogComponent,
  ],
})
export class HomeModule {
}
